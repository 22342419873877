<template>
    <div class="content">
    <img class="h" src="../assets/h-1.jpg" alt="My Image">

    <div style="height: 40px;"></div>

    <router-link to="/two">
      <div class="btn">
        ابدأ المسابقة
    </div>
    </router-link>
   

    <div style="height: 100px;"></div>

    </div>
  </template>
  

  
  <style scoped>
  .h{
    width: 100%;
  }
  .btn{
    width: 420px;
    height: 85px;
    background: #262262;
    border-radius: 7px;
    margin: 0px calc(50% - 210px);
    line-height: 90px;
    text-align: center;
    color: white;
    font-size: 38px;
  }
  </style>


