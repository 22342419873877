<template>
  <div id="app" dir="rtl">
    <div v-if="isLogedIn == false">
      <input
        type="text"
        v-model="password"
        style="margin: 10px calc(50% - 100px); margin-top: 50px; width: 200px"
      />
      <div @click="login"
        class=""
        style="
          height: 50px;
          margin: 10px calc(50% - 60px);
          width: 120px;
          line-height: 50px;
          text-align: center;
          background-color: #262262;
          color: #fff;
        "
      >
        دخول
      </div>
    </div>

    <div v-if="isLogedIn">
      <router-view />
    </div>

    <div class="footer">
      <img src="./assets/footer.jpg" alt="My Image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      phone: "",
      isLogedIn: false,
      password: "",
    };
  },
  methods: {
    login() {
if(this.password=="123321"){
  this.isLogedIn=true;
  localStorage.setItem('password', this.password);
}
    },
  },

  mounted() {
    const savedPassword = localStorage.getItem("password");
    if (savedPassword == "123321") {
      this.isLogedIn = true;
    }
  },
};
</script>

<style>
@font-face {
  font-family: "Sukar-Black";
  src: url("./assets/fonts/Sukar-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Sukar-Regular";
  src: url("./assets/fonts/Sukar-Regular.ttf") format("truetype");
}
body {
  padding: 0px;
  margin: 0px;
  font-family: "Sukar-Black";
}
.content {
  min-height: calc(100vh  - 180px);
}
#app {
  min-height: calc(100vh - 0px);
}

.footer img {
  width: 100%;
  padding: 0px;
}
.active {
  margin: 2px;
  height: 20px;
  width: 20px;
  background: #262262;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
</style>