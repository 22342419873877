<template>
  <div class="content">
    <img class="h" src="../assets/h-2.jpg" alt="My Image" />

    <div style="height: 40px"></div>

    <div class="box">
      <div class="box-header">
        السوأل الاول: متي تأسست شركة ليبيانا.
      </div>
      <div class="box-options">
        <div class="option-item" @click="ans=1; label='';">
          <div class="check">
            <div v-show="ans==1" class="active"></div>
          </div>
          2004
        </div>
        <div class="option-item"   @click="ans=2; label='';">
          <div class="check">
            <div v-show="ans==2" class="active"></div>
          </div>
          2015
        </div>
        <div class="option-item"  @click="ans=3;  label='';">
          <div class="check">
            <div v-show="ans==3" class="active"></div>

          </div>
          2022
        </div>
      </div>
    </div>

    <div  style="color: red; text-align: center;  font-family: 'Sukar-Regular'; font-size: 14px;   width: 340px; margin:8px calc(50% - 170px);">{{ label }}</div>

    <div style="height: 15px"></div>

      <div @click="moveToNext" class="btn">السوأل التالي</div>
    <div style="height: 100px"></div>
  </div>
</template>
  
  <script>
export default {
  name: "Home",
  data() {
    return {
      ans: 0,
      label:'',
    };
  },
  methods: {

  moveToNext() {

      if (this.ans!=0) {
        this.$router.push('/four');
    }else{
this.label="يجب عليك اختيار احد الإجابات"
    }
    },
    }
};
</script>
  
  <style scoped>
.h {
  width: 100%;
}
.btn {
  width: 300px;
  height: 60px;
  background: #262262;
  border-radius: 7px;
  margin: 0px calc(50% - 150px);
  line-height: 60px;
  text-align: center;
  color: white;
  font-size: 28px;
  font-family: 'Sukar-Regular';

}
.input {
  border: 1px solid #262262;
  border-radius: 12px;
  width: 340px;
  margin: 0px calc(50% - 210px);
  height: 70px;
  text-align: center;
  font-size: 20px;
  font-family: "Sukar-Black";
}

::placeholder {
  color: #262262;
}

.box{
  width: 660px;
  margin: 0px calc(50% - 330px);
  background: #E6E7E8;
  border-radius: 12px;

}
.box .box-header{
  font-family: 'Sukar-Regular';
  height: 70px;
  line-height: 70px;
  text-align: center;
  color: white;
  font-size: 34px;
  border-radius: 12px;
  width: 100%;
  background: linear-gradient(to left, #874486, #262262);
}
.box .box-options{
  height: 50px;
  width: calc(100% - 100px);
  margin: 50px 50px 10px 50px;
}

.box-options .option-item{
  float: right;
  width: calc(100% / 3);
  color: #262262;
  font-size: 30px;
  
}
.option-item .check{
  float: right;
  margin-top: 4px;
  height: 24px;
  width: 24px;
  border: 2px solid #262262;
  margin-left: 10px;
  font-family: 'Sukar-Regular';

}
</style>