<template>
  <div class="content">
    <img class="h" src="../assets/h-2.jpg" alt="My Image" />

    <div style="height: 40px"></div>

    <div class="box">
      <div class="box-header">هل سبق وان استخدمت احد الخدمات السابقة</div>
      <div class="box-options">
        <div class="option-item" @click="ans = 1; label='';">
          <div class="check">
            <div v-show="ans == 1" class="active"></div>
          </div>
          نعم
        </div>
        <div class="option-item" style="float: left" @click="ans = 2; label='';">
          <div class="ans">لا</div>
          <div class="check" style="float: left">
            <div v-show="ans == 2" class="active"></div>
          </div>
        </div>
      </div>
    </div>

    <div  style="color: red; text-align: center;  font-family: 'Sukar-Regular'; font-size: 14px;   width: 340px; margin:8px calc(50% - 170px);">{{ label }}</div>


    <!-- Options  -->
    <div class="second-box">
      <!-- Item -->
      <div class="option-item" @click="one = !one">
        <div class="check">
          <div v-show="one" class="active"></div>
        </div>
        جيم كود
      </div>
      <!-- End Item -->
      <!-- Item -->
      <div class="option-item" @click="two = !two">
        <div class="check">
          <div v-show="two" class="active"></div>
        </div>
        ايكارد
      </div>
      <!-- End Item -->
      <!-- Item -->
      <div class="option-item" @click="three = !three">
        <div class="check">
          <div v-show="three" class="active"></div>
        </div>
        فتنس 24
      </div>
      <!-- End Item -->
      <!-- Item -->
      <div class="option-item" @click="four = !four">
        <div class="check">
          <div v-show="four" class="active"></div>
        </div>
        عالم لمسة
      </div>
      <!-- End Item -->
      <!-- Item -->
      <div class="option-item" @click="five = !five">
        <div class="check">
          <div v-show="five" class="active"></div>
        </div>
        اسأل
      </div>
      <!-- End Item -->
      <!-- Item -->
      <div class="option-item" @click="six = !six">
        <div class="check">
          <div v-show="six" class="active"></div>
        </div>
        جوابكم
      </div>
      <!-- End Item -->
      <!-- Item -->
      <div class="option-item" @click="seven = !seven">
        <div class="check">
          <div v-show="seven" class="active"></div>
        </div>
        بلايويز
      </div>
      <!-- End Item -->
    </div>
    <!-- End Options  -->

    <div style="height: 15px"></div>

      <div  @click="moveToNext" class="btn">التالي</div>
    <div style="height: 100px"></div>
  </div>
</template>
  
  <script>
export default {
  name: "Home",
  data() {
    return {
      ans: 0,
      label:'',
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
      seven: false,
    };
  },


  methods: {

moveToNext() {

if (this.ans!=0) {
this.$router.push('/sixteen');
}else{
this.label="يجب عليك اختيار احد الإجابات"
}
},
},
};
</script>
  
  <style scoped>
.h {
  width: 100%;
}
.btn {
  width: 300px;
  height: 60px;
  background: #262262;
  border-radius: 7px;
  margin: 0px calc(50% - 150px);
  line-height: 60px;
  text-align: center;
  color: white;
  font-size: 28px;
  font-family: "Sukar-Regular";
}

::placeholder {
  color: #262262;
}

.box {
  width: 660px;
  margin: 0px calc(50% - 330px);
  background: #e6e7e8;
  border-radius: 12px;
}
.box .box-header {
  font-family: "Sukar-Regular";
  padding-top: 16px;
  height: 60px;
  line-height: 50px;
  text-align: center;
  color: white;
  font-size: 34px;
  border-radius: 12px;
  width: 100%;
  background: linear-gradient(to left, #874486, #262262);
}
.box .box-options {
  height: 50px;
  width: calc(100% - 400px);
  margin: 25px 200px 10px 200px;
}

.box .box-options .option-item {
  float: right;
  width: calc(100% / 2);
  color: #262262;
  font-size: 26px;
  line-height: 34px;
}
.box .option-item .check {
  float: right;
  height: 24px;
  width: 24px;
  border: 2px solid #262262;
  margin-left: 10px;
  font-family: "Sukar-Regular";
}
.ans {
  float: left;
}

.second-box {
  width: 740px;
  height: 150px;
  margin: 0px calc(50% - 370px);
  margin-top: 40px;
}

.second-box .option-item {
  float: right;
  width: calc(100% / 4);
  color: #262262;
  height: 37px;
  font-size: 23px;
  line-height: 30px;
  font-family: "Sukar-Regular";
}
.second-box .option-item .check {
  float: right;
  height: 24px;
  width: 24px;
  border: 2px solid #262262;
  margin-left: 10px;
}
</style>