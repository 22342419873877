<template>
  <div class="content">
    <img class="h" src="../assets/h-16.jpg" alt="My Image" />

    <div style="height: 40px"></div>

    <div v-show="isLoading" class="loading">
      <img
        src="https://orange.cloudsoft.ly/storage/assets/loading.gif"
        alt=""
      />
    </div>
    <div v-if="isLoadSuccess == true" class="vouchar">{{ voucher }}</div>
    <div v-if="isLoadSuccess == true" class="box">
      <QRCodeVue3 :type="rounded" :width="180" :height="180" :value="voucherTel" 

      />
    </div>


    <!-- :qrOptions="{ typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' }"
      :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
      :dotsOptions="{ type: 'square', color: '#05004d' }"
      :cornersSquareOptions="{ type: 'square', color: '#0e013c' }" -->


    <div style="height: 15px"></div>

    <router-link to="/">
      <div class="btn">العودة</div>
    </router-link>
    <div style="height: 100px"></div>
  </div>
</template>
  

<script>
import axios from "axios";
import QRCodeVue3 from "qrcode-vue3";

export default {
  components: {
    QRCodeVue3,
  },
  data() {
    return {
      phone: "",
      isLoading: false,
      voucher: "",
      voucherTel: "",
      isLoadSuccess: false,
    };
  },
  mounted() {
    this.isLoadSuccess = false;

    this.moveToNext();
  },
  methods: {
    moveToNext() {
      this.phone = this.$parent.phone;
      if (this.phone !== "") {
        this.isLoading = true;
        axios
          .post("https://lyquiz-737b4065e18a.herokuapp.com/api/v1/visitors", {
            phone: this.phone,
          })
          .then((response) => {
            this.isLoading = false;
            if (response.status === 201) {
              this.voucher = response.data.visitor.voucher;
              this.voucherTel = "tel:120" + response.data.visitor.voucher;
              this.isLoadSuccess = true;
            }
          })
          .catch((error) => {
            this.isLoading = false;

            console.error(error);
            this.phoneValidation = "رقم الهاتف موجود مسبقا";
          });
      }
    },
  },
};
</script>
  
  <style scoped>
.h {
  width: 100%;
}
.btn {
  width: 300px;
  height: 60px;
  background: #262262;
  border-radius: 7px;
  margin: 0px calc(50% - 150px);
  line-height: 60px;
  text-align: center;
  color: white;
  font-size: 28px;
  font-family: "Sukar-Regular";
}

::placeholder {
  color: #262262;
}

.box {
  border: 1px solid #6a6a6a;
  margin: 0px calc(50% - 100px);
  padding: 10px;
  margin-bottom: 40px;
}
.loading {
  width: 160px;
  margin: 10px calc(50% - 80px);
}
.loading img {
  width: 160px;
}

.vouchar {
  width: 260px;
  height: 50px;
  margin: 10px calc(50% - 130px);
  text-align: center;
  border: 2px dashed #262262;
  font-size: 22px;
  border-radius: 9px;
  line-height: 50px;
}
</style>


