<template>
  <div class="content">
    <img class="h" src="../assets/h-1.jpg" alt="My Image" />

    <div style="height: 40px"></div>

    <input type="text" v-model="phone" @input="phoneValidation=''" :class="{ 'input': true, 'error': phoneValidation !== '' }" placeholder="أدخل رقم هاتفك هنا" />
   <div style="color: red;   font-family: 'Sukar-Regular'; font-size: 14px;   width: 340px; margin:8px calc(50% - 170px);">{{ phoneValidation }}</div>
    <div style="height: 15px"></div>

    <div v-show="isLoading" class="loading">
      <img src="https://orange.cloudsoft.ly/storage/assets/loading.gif" alt="">
    </div>

    <div class="btn" @click="moveToNext">أنطلق و أربح</div>

    <div style="height: 100px"></div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  data() {
    return {
      phone: '',
      phoneValidation: '',
      isLoading:false,
    };
  },
  methods: {
    moveToNext() {
      this.validatePhone();

      if (this.phone !== '' && this.phoneValidation=='') {
        this.isLoading=true;
        axios.get('https://lyquiz-737b4065e18a.herokuapp.com/api/v1/visitors/check?phone='+this.phone)
    .then(response => {
      this.isLoading=false;
      if (response.status === 200) {
        this.$parent.phone=this.phone
        this.$router.push('/three');
      }
    })
    .catch(error => {
      this.isLoading=false;

      console.error(error);
      this.phoneValidation='رقم الهاتف موجود مسبقا';
    });
  
      }
    },

    validatePhone() {
      const validPrefixes = ['091', '092', '093', '094', '095'];
      
      this.phoneValidation= '';

      
      // Check if the phone number is 10 digits long
      if (this.phone.length !== 10) {
        this.phoneValidation='يجب ان يتكون رقم الهاتف من 10 أرقام';
        return 0;
      }
      
      // Check if the phone number starts with a valid prefix
      const prefix = this.phone.substring(0, 3);
      if (!validPrefixes.includes(prefix)) {
        this.phoneValidation= 'رقم الهاتف الذي ادخلته غير صحيح';
        return 0;
      }
      
    }
  }
};
</script>
  
  <style scoped>
.h {
  width: 100%;
}
.btn {
  width: 340px;
  height: 75px;
  background: #262262;
  border-radius: 7px;
  margin: 0px calc(50% - 170px);
  line-height: 80px;
  text-align: center;
  color: white;
  font-size: 32px;
}
.input {
  border: 1px solid #262262;
  border-radius: 12px;
  width: 340px;
  margin: 0px calc(50% - 170px);
  height: 70px;
  text-align: center;
  font-size: 20px;
  font-family: 'Sukar-Black';
}

.error{
  border-color: red;
}

::placeholder {
  color: #262262;
}

.loading{

  width: 160px;
  margin: 10px calc(50% - 80px);
}
.loading img{
  width: 160px;
}
</style>