import { createRouter, createWebHistory } from 'vue-router';
// import Home from './views/Home.vue';
import Home from './views/home.vue';
import Two from './views/two.vue';
import Three from './views/three.vue';
import Four from './views/four.vue';
import Five from './views/five.vue';
import Six from './views/six.vue';
import Seven from './views/seven.vue';
import Eight from './views/eight.vue';
import Nine from './views/nine.vue';
import Ten from './views/ten.vue';
import Eleven from './views/eleven.vue';
import Twelve from './views/twelve.vue';
import Thirteen from './views/thirteen.vue';
import Fourteen from './views/fourteen.vue';
import Fifteen from './views/fifteen.vue';
import Sixteen from './views/sixteen.vue';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/two',
    name: 'Two',
    component: Two
  },
  {
    path: '/three',
    name: 'Three',
    component: Three
  },
  {
    path: '/four',
    name: 'Four',
    component: Four
  },
  {
    path: '/five',
    name: 'Five',
    component: Five
  },
  {
    path: '/six',
    name: 'Six',
    component: Six
  },
  {
    path: '/seven',
    name: 'Seven',
    component: Seven
  },
  {
    path: '/eight',
    name: 'Eight',
    component: Eight
  },
  {
    path: '/nine',
    name: 'Nine',
    component: Nine
  },
  {
    path: '/ten',
    name: 'Ten',
    component: Ten
  },
  {
    path: '/eleven',
    name: 'Eleven',
    component: Eleven
  },
  {
    path: '/twelve',
    name: 'Twelve',
    component: Twelve
  },
  {
    path: '/thirteen',
    name: 'Thirteen',
    component: Thirteen
  },
  {
    path: '/fourteen',
    name: 'Fourteen',
    component: Fourteen
  },
  {
    path: '/fifteen',
    name: 'Fifteen',
    component: Fifteen
  },
  {
    path: '/sixteen',
    name: 'Sixteen',
    component: Sixteen
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;