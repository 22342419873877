<template>
  <div class="content">
    <img class="h" src="../assets/h-8.jpg" alt="My Image" />

    <div style="height: 40px"></div>



    <router-link to="/nine">
      <div class="btn">التالي</div>
    </router-link>
    <div style="height: 100px"></div>
  </div>
</template>
  
  <script>
export default {
  name: "Home",
};
</script>
  
  <style scoped>
.h {
  width: 100%;
}
.btn {
  width: 280px;
  height: 60px;
  background: #262262;
  border-radius: 7px;
  margin: 0px calc(50% - 140px);
  line-height: 60px;
  text-align: center;
  color: white;
  font-size: 28px;
  font-family: "Sukar-Regular";
}

::placeholder {
  color: #262262;
}

</style>